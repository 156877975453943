<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="front">
      <a [routerLink]="['//', product.title.replace(' ', '-')]">
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
          class="img-fluid lazy-loading" 
          alt="{{ product.images[0].alt }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['//', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="['//', product?.title.replace(' ', '-')]">
        <h6>{{ product?.title | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
  
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

