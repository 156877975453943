import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

 
  @Input() class: string; // Default class 
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo

  public today: number = Date.now();
   
  applicant = {
    email: '',
    subject: 'Newsletter From  ItWebinfo.com',
  };

  constructor(private apiService: ApiService ,  private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    // console.log('Application Submitted:', this.applicant);
    // Here you can add code to handle the form submission, e.g., send data to a server
    // const data = { key: 'value' }; // Replace with your actual data
    const data = this.applicant

console.log("data news" ,data )

    this.apiService.postData(data, 'newsletter.php').subscribe(response => {
      // console.log('POST response', response);
      this.toastrService.success('Thankyou for Conact with Us, Team will Be Connect you As Soon As Posiable  .');
    }, error => {
      this.toastrService.success('Thankyou for Conact with Us.');
      console.error('POST error', error);
    });

  }

}
