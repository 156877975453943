import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { ContactComponent } from './pages/account/contact/contact.component';
import { ErrorComponent } from './pages/error/error.component';
import { WatchComponent } from './home/watch/watch.component';

const routes: Routes = [
  // {
  //   path: '',
  //   // redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  // { 
  //   path: '', 
  //   component: WatchComponent, 
  //     loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  // },
  // { 
  //   path: '/', 
  //   component: WatchComponent, 
  //     // loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  // },
  {
    path: 'shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  { 
    path: '404', 
    component: ErrorComponent 
  },
  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'contact-us',
    component: ContactComponent,
  },
  { 
    path: 'contact',
    component: ContactComponent,
  },
  { 
    path: 'news',
    component: ContactComponent,
  },
  // { 
  //   path: 'elements', 
  //   component: ElementsComponent,
  //   loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
