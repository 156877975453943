<app-header-one [sticky]="true"></app-header-one>
<app-breadcrumb [title]="'Contact'" [breadcrumb]="'Contact'"></app-breadcrumb>
<!--section start-->
<section class=" contact-page section-b-space">
    <div class="container">
        <div class="row section-b-space">
            <div class="col-lg-7 map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.4340672172666!2d76.0480532!3d22.9710605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39631772fb57fa4d%3A0x1aeaaccddfab1b64!2sit%20web%20Info%20%7C%20itwebinfo%20%7C%20IT%20Services%20%7C%20Software%20Solutions%20%7C%20Web%20Solutions%20%7C%20Software%20Company!5e0!3m2!1sen!2sin!4v1717012578262!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="col-lg-5">
                <div class="contact-right">
                    <ul>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/phone.png" alt="Generic placeholder image">
                                <h6>Contact Us</h6>
                            </div>
                            <div class="media-body mt-2">
                                <p>(+91) 7582-99-5555</p>
                            </div> 
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <h6>Address</h6>
                            </div>
                            <div class="media-body">
                                <p>IT WEB INFO
                                    <br/>
                                     Shivaji Nagar, Moti Bunglow, Dewas

                                    </p>
                                <p>Dewas – 455001</p>
                            </div>
                            <div class="media-body">
                                <p>IT WEB INFO 
                                    <br/>
                                1st Floor, Office ON, near SBI, C Block, Sector 2, Noida, 
                                    </p>
                                <p>Noida - 201301</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/email.png" alt="Generic placeholder image">
                                <h6>Email</h6>
                            </div>
                            <div class="media-body">
                                <p>support&#64;itwebinfo.com</p>
                                <p>contact&#64;itwebinfo.com</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <form class="theme-form"  (ngSubmit)="onSubmit()" #ContactForm="ngForm">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="name">First Name</label>
                            <input type="text" class="form-control" id="name" placeholder="Enter Your First name"  name="Fname" [(ngModel)]="applicant.Fname"  required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">Last Name</label>
                            <input type="text" class="form-control" id="last-name" placeholder="Enter Your Last name"  name="Lname"  [(ngModel)]="applicant.Lname"  required="">
                        </div>
                        <div class="col-md-6">
                            <label for="review">Phone number</label>
                            <input type="text" class="form-control" id="review" placeholder="Enter your number"  name="phone" [(ngModel)]="applicant.phone" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" id="email" placeholder="Enter your Email"  name="email" [(ngModel)]="applicant.email"  required="">
                        </div>
                        <div class="col-md-12">
                            <label for="review">Write Your Message</label>
                            <textarea class="form-control" placeholder="Write Your Message"  name="message" id="exampleFormControlTextarea1" [(ngModel)]="applicant.message" rows="6"></textarea>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-solid black-btn" type="submit" [disabled]="!ContactForm.form.valid" >Send Your Message</button>
                        </div>
                    </div>
                </form>
                <p class="dis_success" *ngIf="applicant.subject_message">{{applicant.subject_message }}</p>
            </div>
        </div>
    </div>
</section>
<app-footer-one ></app-footer-one>

<!--Section ends-->