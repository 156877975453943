import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  
  applicant = {
    Fname: '',
    Lname: '',
    email: '',
    phone: '',
    message: '',
    subject_message:'',
    subject: 'Lead From Contact us  page itwebinfo.com',
  };

  constructor(private apiService: ApiService ,  private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    // console.log('Application Submitted:', this.applicant);
    // Here you can add code to handle the form submission, e.g., send data to a server
    // const data = { key: 'value' }; // Replace with your actual data
    const data = this.applicant

    this.apiService.postData(data, 'mail_api.php').subscribe(response => {

      this.applicant.Fname= '';
      this.applicant.Lname= '';
      this.applicant.email= '';
      this.applicant.phone= '';
      this.applicant.message= '';
    this.applicant.subject_message= 'Thank you for Contacting Us, Our team will get back to you shortly';


      // console.log('POST response', response);
      this.toastrService.success('Thankyou for Conact with Us, Team will Be Connect you As Soon As Posiable  .');
    }, error => {
      this.toastrService.success('Thankyou for Conact with Us.');
      console.error('POST error', error);
    });

  }

}
